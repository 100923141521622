<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                    v-show="!zoneDetails.hasOwnProperty('image')"
                    height="260px"
                    class="rounded-t-lg"
                    type="image"
                  ></v-skeleton-loader> -->
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="zoneDetails.hasOwnProperty('image') &&
              zoneDetails.image != '' &&
              zoneDetails.image != null
              ? zoneDetails.image
              : zoneImgURL
              " :lazy-src="zoneDetails.hasOwnProperty('image') &&
    zoneDetails.image != '' &&
    zoneDetails.image != null
    ? zoneDetails.image
    : zoneImgURL
    " alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4">
              Welcome to
              {{
                zoneDetails.hasOwnProperty("zoneName")
                ? zoneDetails.zoneName
                : ""
              }}
            </div>
          </div>
          <!-- :style="'height:'+windowHeight+'px'" -->
          <v-card-text class="pa-2" :style="'height:' + windowHeight + 'px'">
            <v-container fluid pa-0 style="height: 100%;display: inline-grid;">
              <v-row no-gutters>
                <v-col cols="12" class="px-4 mt-2 text-center color-black" style="font-size: 12px">

                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2"><v-col cols="12" class="px-4 text-center">
                  <p class="mb-4">
                    Please enter your license plate
                  </p>
                  <v-text-field class="sms-input" v-model="licencePlate" outlined label="License Plate" maxlength="10"
                    :rules="[rules.alphaNumeric]" autofocus @keyup="uppercase" v-on:keyup.enter="findUser()" clearable
                    height="80px" hide-details="auto"></v-text-field>
                </v-col></v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text" :loading="findUserBtnLoading"
                    @click="findUser" :disabled="disableFindUserBtn">Find</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
          <v-dialog v-model="errDialog" max-width="fit-content">
            <v-card class="pa-0">
              <v-card-title class="justify-center">
                <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
              </v-card-title>
              <v-card-text class="text-center mt-5">
                <span style="font-size: 20px">
                  {{ errMsg }}
                </span>
              </v-card-text>
              <v-card-actions class="text-center pb-6">
                <v-container pa-0 fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                        @click="closeErrDialog">OK</v-btn>
                    </v-col>
                    <v-col cols="12" class="my-2">
                      <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                        @click="navigateToConfirmHostPass">Proceed Anyway</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import rules from "@/utils/rules";

// import loggerHelper from "../loggerHelper";
// import { mapGetters } from "vuex";
export default {
  name: "FindUser",
  components: {},
  data: () => ({
    windowHeight: window.innerHeight - 500,
    isContactValid: true,
    errDialog: false,
    merchantId: '',
    errMsg: "",
    contactObj: {},
    rules: rules,
    licencePlate: "",
    findUserBtnLoading: false,
  }),
  props: {
    routeData: {
      type: Object,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("SET_FINDUSER_DATA", {});
      const params = new URLSearchParams(window.location.search)
      vm.getZoneDetails('?zcode', params.get('zcode'))
      vm.merchantId = params.has('mid') !== null ? params.get('mid') : ''
    });
  },
  computed: {
    ...mapGetters({
      zoneDetails: "getterZoneDetails",
    }),
    disableFindUserBtn() {
      let disabled = (
        this.licencePlate == "" ||
        this.licencePlate == null ||
        /^[a-z0-9]+$/i.test(this.licencePlate) === false
      );
      return disabled
    },
  },
  created() { },
  async mounted() {
    var self = this;
    this.findUserBtnLoading = false;
    const params = new URLSearchParams(window.location.search)
    await self.getZoneDetails('?zcode', params.get('zcode'))
    // switch (searchParamKey) {
    //   case "?zcode":
    //     await self.getZoneDetails('?zcode');
    //     break;
    //   case "?gateid":
    //     //await self.getZoneDetails(searchParamKey, searchParamValue);
    //     break;
    // }
    self.merchantId = params.has('mid') !== null ? params.get('mid') : ''
  },
  methods: {
    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
      } catch (error) {
        console.log(error);
      }
    },
    async findUser() {
      try {
        if (this.licencePlate != "" && this.licencePlate != null) {
          this.findUserBtnLoading = true;
          let postData = {
            searchType: "vehicleno",
            searchValue: this.licencePlate,
            zid: this.zoneDetails.zid,
          }
          const findUser = await APIHelper(
            "POST",
            "/api/v2/findBooking",
            postData
          );
          if (findUser?.data?.status == true) {
            this.$store.commit("SET_FINDUSER_DATA", findUser.data?.data);
            this.$router.push({ path: "/confirmreservation" });
          } else {
            this.errMsg = "We couldn’t find your plate. Please try entering it again."
            this.errDialog = true;
          }
          this.findUserBtnLoading = false;
        }
      } catch (error) {
        this.findUserBtnLoading = false;
        console.log(error);
      }
    },
    navigateToConfirmHostPass() {
      this.$store.commit("SET_FINDUSER_DATA", { vehicleNo: this.licencePlate });
      this.$router.push({ path: "/confirmreservation" });
    },
    closeErrDialog() {
      this.errDialog = false;
      this.licencePlate = "";
    }
  },
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 30px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.country-selector.md.has-value .country-selector__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
  box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
  box-shadow: none !important;
}

.sms-input input {
  font-size: 36px;
  font-weight: 500;
  color: #44515a !important;
}

.sms-input>.v-input__control>.v-input__slot>.v-input__append-inner {
  margin-top: 28px !important;
}

.sms-input>.v-input__control>.v-input__slot>.v-text-field__slot>.v-label {
  top: 28px !important
}

.sms-input>.v-input__control>.v-input__slot>.v-text-field__slot>.v-label--active {
  top: 18px !important
}
</style>
  